import "./NavBar.scss";

const Navbar = () => {
  const navbarData = [
    {
      mainMenu: "Home",
      url: "/",
      subMenu: [],
    },
    {
      mainMenu: "Cow",
      url: "/cow",
      subMenu: [
        {
          menu: "Gir",
          url: "/gir",
        },
        {
          menu: "Sahiwal",
          url: "/sahiwal",
        },
        {
          menu: "HF",
          url: "/hf",
        },
        {
          menu: "Rathi",
          url: "/rathi",
        },
        {
          menu: "Tharparkar",
          url: "/tharparkar",
        },
        {
          menu: "Jersey",
          url: "/jersey",
        },
        {
          menu: "Hariyana",
          url: "/hariyana",
        },
        {
          menu: "Sindhi",
          url: "/sindhi",
        },
        {
          menu: "Punganur",
          url: "/punganur",
        },
        {
          menu: "Kankrej",
          url: "/kankrej",
        },
        {
          menu: "Deoni",
          url: "/deoni",
        },
        {
          menu: "Venchur",
          url: "/venchur",
        },
        {
          menu: "Kasargod Kullan",
          url: "/kasargod-kullan",
        },
        {
          menu: "ONGOLE",
          url: "/ongole",
        },
        {
          menu: "Vilwadri",
          url: "/vilwadri",
        },
        {
          menu: "Odisha Desi",
          url: "/odisha-desi",
        },
        {
          menu: "Mewati",
          url: "/mewati",
        },
        {
          menu: "Kapila",
          url: "/kapila",
        },
        {
          menu: "Cheruvally",
          url: "/cheruvally",
        },
      ],
    },
    {
      mainMenu: "Buffalo",
      url: "/buffalo",
      subMenu: [],
    },
    {
      mainMenu: "Goat",
      url: "/goat",
      subMenu: [],
    },
    {
      mainMenu: "Sheep",
      url: "/sheep",
      subMenu: [],
    },
    {
      mainMenu: "About Us",
      url: "/about-us",
      subMenu: [],
    },
    {
      mainMenu: "Contact Us",
      url: "/contant-us",
      subMenu: [],
    },
  ];

  return (
    <div className="navbar">
      <ul>
        {navbarData.map((navBar: any, index: any) => (
          <li
            key={index}
            className={`${navBar.subMenu.length > 0 ? "has-sub-menu" : ""}`}
          >
            <a href={`${navBar.url}`}>{navBar.mainMenu}</a>
            {navBar.subMenu.length > 0 && (
              <ul>
                {navBar.subMenu.map((subMenu: any, idx: any) => (
                  <li key={idx}>
                    <a href={`${navBar.url}${subMenu.url}`}>{subMenu.menu}</a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
