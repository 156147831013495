import Banner from "../components/banner/Banner";
import BlogSection from "../components/blogSection/BlogSection";
import ProductSection from "../components/productSection/ProductSection";
import SupportSection from "../components/supportSection/Support";

const Main = () => {
  return (
    <>
      <Banner />
      <SupportSection />
      <ProductSection />
      <BlogSection />
    </>
  );
};

export default Main;
