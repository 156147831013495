import Navbar from "../navBar/NavBar";
import logo from "../../assets/images/logo.png";
import "./Header.scss";

const Header = () => {
  return (
    <header className="header-main">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="BhainsWala" />
            </a>
          </div>
          <Navbar />
          <div className="send-inquiry-btn">
            <a href="">Send Inquiry</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
