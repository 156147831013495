import Carousel from "react-bootstrap/Carousel";
import banner1 from "../../assets/images/banner1.jpg";
import banner2 from "../../assets/images/banner2.jpg";
import banner3 from "../../assets/images/banner3.jpg";
import "./Banner.scss";

const Banner = () => {
  return (
    <div className="main-banner">
      <Carousel indicators={false}>
        <Carousel.Item>
          <div className="banner-img">
            <img src={banner1} alt="banner 1" />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="banner-img">
            <img src={banner2} alt="banner 2" />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="banner-img">
            <img src={banner3} alt="banner 3" />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Banner;
