import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import banner3 from "../../assets/images/banner3.jpg";
import blogImg1 from "../../assets/images/blogImg1.jpeg";
import blogImg2 from "../../assets/images/blogImg2.jpg";
import blogImg3 from "../../assets/images/blogImg3.jpeg";
import "swiper/css";
import "swiper/css/navigation";
import "./BlogCard.scss";

const BlogCard = () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={4}
        navigation={true}
        loop
      >
        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={banner3} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={blogImg1} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={blogImg2} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={blogImg3} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={blogImg1} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-card">
            <div className="img-section">
              <img src={blogImg2} alt="blog 1" />
            </div>
            <div className="content">
              <div className="blog-detail">
                <div className="publish-date">
                  OCTOBER 23, 2024 - 10 MIN READ
                </div>
                <div className="publish-by">by Admin</div>
              </div>
              <strong>
                The standard Lorem Ipsum passage, used since the 1500s
              </strong>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default BlogCard;
