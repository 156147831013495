import { FaPaw } from "react-icons/fa";
import prodImg from "../../assets/images/prod-img.jpg";
import prodImg2 from "../../assets/images/prod-img2.jpg";
import "./ProductCard.scss";

const ProductCard = () => {
  return (
    <>
      <div className="card">
        <div className="img-container">
          <img src={prodImg} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag verified">Verified</div>
      </div>
      <div className="card">
        <div className="img-container">
          <img src={prodImg2} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag not-verified">Not Verified</div>
      </div>
      <div className="card">
        <div className="img-container">
          <img src={prodImg} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag verified">Verified</div>
      </div>
      <div className="card">
        <div className="img-container">
          <img src={prodImg} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag not-verified">Not Verified</div>
      </div>
      <div className="card">
        <div className="img-container">
          <img src={prodImg} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag verified">Verified</div>
      </div>
      <div className="card">
        <div className="img-container">
          <img src={prodImg} alt="Product" />
          <div className="added-timeline-flag">Added 2 week(s) ago</div>
        </div>
        <div className="content">
          <div className="content-row">
            <div className="prod-name">Punganur Cow</div>
            <div className="price">&#8377; 50000</div>
          </div>
          <div className="content-row">
            <div className="place">Lucknow, Uttar Pradesh</div>
            <div className="other-info">
              <label>Biyat</label> : 3
            </div>
          </div>
          <div className="content-row">
            <div className="age">Age: 5 yr 5 Mo</div>
            <div className="other-info">
              <label>Avg Wt</label> : 43Kg
            </div>
          </div>
          <a href="/" className="inquiry-btn">
            <span>Buy/Book Now</span>
            <FaPaw />
          </a>
        </div>
        {/* Flags part starts here */}
        <div className="verified-flag verified">Verified</div>
      </div>
    </>
  );
};

export default ProductCard;
