import ProductCard from "../productCard/ProductCard";
import SectionHeading from "../sectionHeading/SectionHeading";
import "./ProductSection.scss";

const ProductSection = () => {
  return (
    <div className="product-section">
      <div className="container">
        <SectionHeading heading="Newly Added Livestock" />
        <div className="prod-card-container">
          <ProductCard />
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
