import { FaFacebook, FaInstagram } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="container">
        <div className="footer-container">
          <div className="logo-area">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="Bhainswala Logo" />
                <i>BhainsWala</i>
              </a>
              <a href="mailto:support@bhainswala.com">support@bhainswala.com</a>
            </div>
          </div>
          <div className="links-area">
            <div className="footer-menu">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <h5>Products</h5>
              <ul>
                <li>
                  <a href="/cow">Cow</a>
                </li>
                <li>
                  <a href="/buffalo">Buffalo</a>
                </li>
                <li>
                  <a href="/goat">Goat</a>
                </li>
                <li>
                  <a href="/sheep">Sheep</a>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <h5>Legal</h5>
              <ul>
                <li>
                  <a href="/terms-conditions">Terms of Service</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom-footer">
            <span>&copy; Bhainswala. All Rights Reserved</span>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/bhainswala01" target="blank">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/bhains.wala" target="blank">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
