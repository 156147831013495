import "./SectionHeading.scss";

const SectionHeading = (props: any) => {
  const { heading } = props;
  return (
    <div className="section-heading">
      <h3>{heading}</h3>
    </div>
  );
};

export default SectionHeading;
