import { FaHeadset } from "react-icons/fa";
import "./Support.scss";

const SupportSection = () => {
  return (
    <div className="support-section">
      <div className="container">
        <div className="suppor-container">
          <div className="support-content">
            <FaHeadset />
            <div className="content">
              <label>Support 24/7</label>
              <span>Lorem Ipsum is simply dummy text</span>
            </div>
          </div>
          <div className="support-content">
            <FaHeadset />
            <div className="content">
              <label>Support 24/7</label>
              <span>Lorem Ipsum is simply dummy text</span>
            </div>
          </div>
          <div className="support-content">
            <FaHeadset />
            <div className="content">
              <label>Support 24/7</label>
              <span>Lorem Ipsum is simply dummy text</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSection;
