import BlogCard from "../blogCard/BlogCard";
import SectionHeading from "../sectionHeading/SectionHeading";
import "./BlogSection.scss";

const BlogSection = () => {
  return (
    <div className="blog-section">
      <div className="container">
        <SectionHeading heading="Our Blog" />
        <div className="blog-card-container">
          <BlogCard />
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
